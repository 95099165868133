<template>
    <div>
        <q-processamento
            ref="qProcessamento"
            :nome="nome"
            :id-cliente="idCliente"
            :id-dependente="idDependente"
            :todos="todos"
            @linhaClick="$_onRowClick"
        />
        <q-efetuados
            ref="qEfetuados"
            class="q-top-space"
            :nome="nome"
            :id-cliente="idCliente"
            :id-dependente="idDependente"
            :todos="todos"
            @linhaClick="$_onRowClick"
        />
        <q-detalhes-pagamento
            ref="modalDetalhes"
        />
    </div>
</template>

<script>

import QProcessamento from '@/components/Pagamentos/processamento'
import QEfetuados from '@/components/Pagamentos/efetuados'
import QDetalhesPagamento from '@/views/DetalhesPagamento'

export default {
    name: 'PgPagamentos',

    components: {
        QProcessamento,
        QEfetuados,
        QDetalhesPagamento
    },

    props: {
        nome: {
            type: String,
            required: true
        },

        idCliente: {
            type: String,
            default: null
        },

        idDependente: {
            type: String,
            default: null
        },

        todos: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        $_onRowClick(linha) {
            this.$refs.modalDetalhes.open(linha)
        }
    }
}
</script>

<style>
.q-top-space {
    margin-top: 10px
}
</style>

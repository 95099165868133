<template>
    <div>
        <q-table
            :title="titulo"
            :rows="dados"
            :columns="colunas"
            :row-key="campoChave"
            :selection="selecao"
            :virtual-scroll="rolagemVirtual"
            :pagination="paginacao"
            v-model:selected="itensSelecionados"
            @selection="$_selecaoItem"
            @row-click="$_onRowClick"
            :hide-bottom="!exibeRodape"
            wrap-cells
            :class="ClasseTabela"
        >
            <template
                v-slot:bottom
                v-if="!exibePaginacao"
            >
                <slot name="bottom" />
            </template>
        </q-table>
    </div>
</template>

<script>
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QLinsTable',

    props: {
        titulo: {
            type: String,
            default: ''
        },
        dados: {
            type: Array,
            required: true
        },
        colunas: {
            type: Array,
            required: true
        },
        campoChave: {
            type: String,
            default: 'id'
        },
        rolagemVirtual: {
            type: Boolean,
            default: false
        },
        exibeRodape: {
            type: Boolean,
            default: true
        },
        selecao: {
            type: String,
            default: 'none'
        },
        validaItemSelecionado: {
            type: Function,
            default: () => { return { exibeSelecao: true, mensagem: '' } }
        },
        linhasPorPagina: {
            type: Number,
            default: 10
        },
        exibePaginacao: {
            type: Boolean,
            default: false
        },
        exibirMarcarTodos: {
            type: Boolean,
            default: false
        },
        marcarLinhaAoClicar: {
            type: Boolean,
            default: false
        }
    },

    mixins: [MixinMessages],

    data() {
        return {
            paginacao: {
                rowsPerPage: 10
            },
            itensSelecionados: []

        }
    },

    computed: {
        ClasseTabela(){
            return this.exibirMarcarTodos ? 'table-margin' : 'table-margin ocultar-marcar-todos'
        }
    },

    created() {
        this.paginacao.rowsPerPage = this.linhasPorPagina
    },

    methods: {
        $_selecaoItem(details) {
            this.$nextTick(() => {
                this.$emit('itensSelecionados', this.itensSelecionados)
                const validaItem = this.validaItemSelecionado(details.rows[0], details.added)
                if (!validaItem.exibeSelecao) {
                    if (details.added) {
                        this.itensSelecionados.pop()
                    } else {
                        this.itensSelecionados.push(details.rows[0])
                    }
                    this.$emit('itensSelecionados', this.itensSelecionados)
                    this.warningMessage({
                        title: 'Atenção',
                        message: validaItem.mensagem
                    })
                }
            })
        },

        $_onRowClick(event, row) {
            this.$emit('linhaClick', row)
            if (this.marcarLinhaAoClicar){
                const added = Boolean(!this.itensSelecionados.includes(row))
                const validaItem = this.validaItemSelecionado(row, added)
                if (validaItem.exibeSelecao){
                    if (added){
                        this.itensSelecionados.push(row)
                    } else {
                        this.itensSelecionados.splice(this.itensSelecionados.indexOf(row), 1)
                    }
                } else {
                    this.warningMessage({
                        title: 'Atenção',
                        message: validaItem.mensagem
                    })
                }
                this.$emit('itensSelecionados', this.itensSelecionados)
            }
        }
    }
}
</script>

<style lang="scss">
.table-margin {
    margin-top: 5px;
}

.ocultar-marcar-todos{
    div{
        table{
            thead{
                tr{
                    th{
                        .q-checkbox {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }

}

</style>

<template>
    <q-panel titulo="SEGUNDA VIA DO BOLETO">
        <q-checkbox
            v-model="receberEmailTelefone"
            label="Receber via e-mail ou telefone."
        />
        <div v-if="receberEmailTelefone">
            <div class="col text-subtitle1 text-weight-medium">
                Informe-nos seus dados de contato para lhe enviarmos a segunda via do boleto.
            </div>

            <div class="col q-mt-md">
                <div class="col">
                    <q-input
                        filled
                        v-model="email"
                        label="E-mail"
                        type="email"
                        :disable="naoPossuiEmail"
                    />
                </div>

                <q-checkbox
                    class="q-mt-sm"
                    v-model="naoPossuiEmail"
                    label="Não possuo e-mail."
                />

                <div
                    v-if="naoPossuiEmail"
                    class="col q-mt-sm q-mb-sm"
                >
                    <q-input
                        filled
                        v-model="telefone"
                        label="Telefone"
                        type="tel"
                        mask="(##) # #### - ####"
                    />
                </div>
            </div>
        </div>

        <q-btn
            class="full-width q-top-space"
            style="height: 50px;"
            :label="`${receberEmailTelefone ? 'Enviar' : 'Visualizar'} Segunda Via`"
            color="green"
            :icon="receberEmailTelefone ? 'send' : 'visibility'"
            @click="$_finalizar()"
        />
    </q-panel>
</template>

<script>

import QPanel from '@/components/shared/QPanel'

import TransacaoService from '@/services/transacao'

import MixinBoleto from '@/mixins/mixinBoleto'
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QSegundaViaBoleto',

    components: {
        QPanel
    },

    mixins: [
        MixinBoleto,
        MixinMessages
    ],

    props: {
        idTransacao: {
            type: String,
            default: null
        },

        boleto: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            telefone: '',
            email: '',
            naoPossuiEmail: false,
            receberEmailTelefone: false
        }
    },

    watch: {
        naoPossuiEmail: function(value) {
            value ? this.email = '' : this.telefone = ''
        }
    },

    methods: {
        $_abrirBoleto() {
            if (this.boleto.situacao === this.BOLETO_SITUACAO_REGISTRADO && this.boleto.url_boleto) {
                window.open(this.boleto.url_boleto)
            }
        },

        $_finalizar() {
            if (!this.receberEmailTelefone) {
                this.$_abrirBoleto()
            } else {
                if (this.telefone === '' && this.email === '') {
                    this.warningMessage({
                        title: 'Atenção!',
                        message: `Preencha o ${this.naoPossuiEmail ? 'telefone' : 'e-mail'} para imprimir a segunda via.`
                    })
                } else {
                    if (this.email && !this.$_validateEmail(this.email)) {
                        this.warningMessage({
                            title: 'Atenção!',
                            message: 'Preencha um e-mail válido'
                        })
                    } else if (this.telefone && this.telefone.replace(/[().\s-]+/g, '').length < 11) {
                        this.warningMessage({
                            title: 'Atenção!',
                            message: 'Preencha um telefone válido'
                        })
                    } else {
                        this.$_atualizaContatoBoleto()
                    }
                }
            }
        },

        $_atualizaContatoBoleto() {
            this.showLoading({ message: 'Salvando informações de contato, Aguarde!' })
            TransacaoService.patch(this.idTransacao, {
                boleto: {
                    sacado_celular: this.telefone ? this.telefone.replace(/[().\s-]+/g, '') : null,
                    sacado_email: this.email ? this.email : null,
                    boleto_enviado: false
                }
            }).then(() => {
                this.hideLoading()
                this.successMessage({
                    title: 'Enviando Segunda Via',
                    message: `Em breve você receberá a segunda via do boleto por ${this.naoPossuiEmail ? 'sms' : 'e-mail'}.`
                })
            })
                .catch(e => {
                    this.hideLoading()
                    this.errorMessage({
                        title: 'Ops, Algo de errado aconteceu!',
                        message: 'Erro ao salvar informações de contato',
                        error: e
                    })
                })
        },

        $_validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(email).toLowerCase())
        }
    }
}
</script>

<style>

</style>

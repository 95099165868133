<template>
    <q-panel titulo="DETALHES">
        <div :class="`row justify-evenly bg-${$q.dark.isActive ? 'dark' : 'white'}`">
            <div class="col-6">
                <span class="text-subtitle2 text-weight-medium text-blue-grey-4"> Data/Hora </span> <br>
                <span class="text-subtitle2">{{ dataHora }}</span>
            </div>
            <div class="col-6">
                <span class="text-subtitle2 text-weight-medium text-blue-grey-4"> Situação </span> <br>
                <span class="text-subtitle2">{{ situacao }}</span>
            </div>
        </div>
    </q-panel>
</template>

<script>
import { date } from 'quasar'

import QPanel from '@/components/shared/QPanel'

import mixinOrdemPagamento from '@/mixins/mixinOrdemPagamento'

export default {
    name: 'QSituacaoOrdem',

    components: {
        QPanel
    },

    mixins: [mixinOrdemPagamento],

    props: {
        ordem: {
            type: Object,
            required: true
        }
    },

    data() {
        return {

        }
    },

    computed: {
        dataHora() {
            try {
                return `${date.formatDate(this.ordem.data_emissao, 'DD/MM/YYYY')} ${date.formatDate(this.ordem.data_emissao, 'HH:mm')}`
            } catch (e) {
                return '-'
            }
        },

        situacao() {
            try {
                return this.STATUS_PAGAMENTO[this.ordem.status_pagamento]
            } catch (e) {
                return '-'
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

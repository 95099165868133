<template>
    <div class="row-12">
        <div class="col-12">
            <p>1. Acesse seu Internet Banking ou app de pagamentos.</p>
            <p>2. Escolha pagar via Pix.</p>
            <p>3. Copia e cola o código do pagamento ou escaneie o QR.</p>
        </div>
        <div class="col-12">
            <q-input
                filled
                v-model="linkPix"
                bottom-slots
                readonly
            >
                <template v-slot:append>
                    <q-btn
                        color="primary"
                        label="Copiar"
                        v-clipboard:copy="linkPix"
                        v-clipboard:success="$_successCopyLinkPix"
                        v-clipboard:error="$_errorCopyLinkPix"
                    />
                </template>
            </q-input>
        </div>
        <div class="col-12">
            <div class="row justify-center">
                <img
                    :src="qrcode"
                    alt="QRCode"
                    class="qrcode"
                >
            </div>
        </div>
    </div>
</template>

<script>

import QPanel from '@/components/shared/QPanel'
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QPix',

    props: {
        pix: {
            type: Object,
            default: () => ({
                link_emv: '',
                qrcode: {
                    tipo_arquivo: '',
                    imagem_base64: ''
                }
            })
        }
    },

    mixins: [
        MixinMessages
    ],

    component: {
        QPanel
    },

    computed: {
        qrcode() {
            return `data:image/${this.pix.qrcode.tipo_arquivo};base64,${this.pix.qrcode.imagem_base64}`
        },

        linkPix() {
            return this.pix.link_emv
        }
    },

    methods: {
        $_successCopyLinkPix() {
            this.successMessage({
                title: 'PIX',
                message: 'Link do Pix Copiado!'
            })
        },

        $_errorCopyLinkPix() {
            this.errorMessage({
                title: 'Ops, algo de errado aconteceu!',
                message: 'Não foi possível copiar o link!'
            })
        }
    }
}
</script>

<style>
.qrcode {
    width: 40%;
    margin-bottom: 70px;
}
</style>

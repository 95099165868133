<template>
    <div class="col">
        <div
            v-if="boleto.situacao !== BOLETO_SITUACAO_REGISTRADO "
            class="justify-center"
        >
            <div class="center">
                <div
                    v-if="boleto.situacao === BOLETO_SITUACAO_REJEITADO "
                    class="q-my-sm center"
                >
                    <q-card>
                        <q-card-section>
                            <span class="text-red-14 text-subtitle2 text-weight-medium">
                                Ocorreu um problema e o boleto gerado não poderá ser pago. Por favor, tente selecionar novamente suas parcelas.
                            </span>
                        </q-card-section>
                    </q-card>
                </div>
                <div
                    v-else-if="boleto.situacao === BOLETO_SITUACAO_EMITIDO"
                    class="q-my-sm"
                >
                    <q-card>
                        <q-card-section>
                            <span class="text-red-14 text-subtitle2 text-weight-medium">
                                O boleto ainda não está disponível. Por favor, tente novamente mais tarde.
                            </span>
                        </q-card-section>
                    </q-card>
                </div>
            </div>
        </div>
        <div class="row q-mt-sm">
            <div class="col">
                <span class="text-caption text-weight-medium text-blue-grey-4"> Código do boleto </span> <br>
                <span class="text-subtitle2 wrap">{{ ExibeLinhaDigitavel || '-' }}</span>
                <q-btn
                    v-if="boleto.situacao == BOLETO_SITUACAO_REGISTRADO "
                    class="full-width q-top-space"
                    style="height: 50px;"
                    label="Copiar Linha Digitável"
                    color="primary"
                    icon="save"
                    :disabled="boleto.situacao !== BOLETO_SITUACAO_REGISTRADO || !linhaDigitavel"
                    v-clipboard:copy="linhaDigitavel"
                    v-clipboard:success="$_successCopyLinhaDigitavel"
                    v-clipboard:error="$_errorCopyLinhaDigitavel"
                />
            </div>
        </div>
        <div class="row q-mt-sm">
            <div class="col">
                <span class="text-caption text-weight-medium text-blue-grey-4"> Código </span> <br>
                <span class="text-subtitle2">{{ boleto.nosso_numero || '-' }}</span>
            </div>
            <div class="col">
                <span class="text-caption text-weight-medium text-blue-grey-4"> Número Doc. </span> <br>
                <span class="text-subtitle2">{{ boleto.numero_documento || '-' }}</span>
            </div>
        </div>
        <div class="row q-mt-sm">
            <div class="col">
                <span class="text-caption text-weight-medium text-blue-grey-4"> Vencimento </span> <br>
                <span class="text-subtitle2">{{ FormataDataVencimento || '-' }}</span>
            </div>
            <div class="col">
                <span class="text-caption text-weight-mediun text-blue-grey-4"> Pagamento </span> <br>
                <span class="text-subtitle2">{{ dataPagamento || '-' }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import { date } from 'quasar'
import MixinBoleto from '@/mixins/mixinBoleto'
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QDetalhesBoleto',

    mixins: [
        MixinBoleto,
        MixinMessages
    ],

    props: {
        boleto: {
            type: Object,
            required: true
        }
    },

    computed: {
        ExibeLinhaDigitavel(){
            return (this.linhaDigitavel && this.boleto.situacao === this.BOLETO_SITUACAO_REGISTRADO) ? this.linhaDigitavel : false
        },
        FormataDataVencimento(){
            return date.formatDate(this.boleto.data_vencimento + 'T00:00:00', 'DD/MM/YYYY')
        },

        dataPagamento() {
            return date.formatDate(this.boleto.pagamento_data, 'DD/MM/YYYY')
        },

        linhaDigitavel() {
            return this.boleto.linha_digitavel
        }
    },

    methods: {
        $_successCopyLinhaDigitavel() {
            this.successMessage({
                title: 'Boleto',
                message: 'Linha digitável do boleto Copiada!'
            })
        },

        $_errorCopyLinhaDigitavel() {
            this.errorMessage({
                title: 'Ops, algo de errado aconteceu!',
                message: 'Não foi possível copiar a linha digitável do boleto!'
            })
        }
    }
}
</script>

<style lang="scss">

</style>

<template>
    <q-modal-view
        id="modDetalhesPagamento"
        ref="modal"
        title="Detalhes do pagamento"
    >
        <div
            class="col"
            v-if="!transacao"
        >
            <q-banner-mensagem
                mensagem="Problema ao gerar o pagamento, entre em contato com a central de crédito."
            />
        </div>
        <div
            class="col"
            v-else
        >
            <q-situacao-transacao
                class="q-my-sm q-py-sm"
                :ordem="ordemPagamento"
            />
            <q-parcelas-transacao
                class="q-my-sm q-py-sm"
                :parcelas="parcelas"
            />
            <q-detalhes-transacao
                class="q-my-sm q-py-sm"
                v-if="transacao"
                :transacao="transacao"
                :status-pagamento="ordemPagamento.status_pagamento"
            />
            <q-segunda-via-boleto
                class="q-my-sm q-py-sm"
                :boleto="transacao.boleto"
                :id-transacao="transacao.id"
                v-if="transacao.boleto && ordemPagamento.status_pagamento !== ORDEM_CANCELADA && transacao.boleto.situacao === BOLETO_SITUACAO_REGISTRADO"
            />
            <q-segunda-via-pix
                class="q-my-sm q-py-sm"
                :transacao-pix="transacao.pix"
                v-if="transacao.pix && ordemPagamento.status_pagamento !== ORDEM_CANCELADA && transacao.pix.situacao === PIX_EMITIDO"
            />
        </div>
    </q-modal-view>
</template>

<script>
import QModalView from '@/components/shared/QModalView'
import QBannerMensagem from '@/components/shared/QBannerMensagem'
import QSituacaoTransacao from '@/components/DetalhesPagamento/situacaoTransacao'
import QParcelasTransacao from '@/components/DetalhesPagamento/parcelasTransacao'
import QDetalhesTransacao from '@/components/DetalhesPagamento/detalhesTransacao'

import OrdensPagamentService from '@/services/ordens_pagamento'

import QSegundaViaBoleto from '@/components/DetalhesPagamento/segundaViaBoleto'
import QSegundaViaPix from '@/components/DetalhesPagamento/segundaViaPix'

import MixinBoleto from '@/mixins/mixinBoleto'
import MixinPix from '@/mixins/mixinPix'
import MixinMessages from '@/mixins/mixinMessages'
import MixinOrdemPagamento from '@/mixins/mixinOrdemPagamento'

export default {
    name: 'QModalDetalhesPagamento',

    components: {
        QModalView,
        QBannerMensagem,
        QSituacaoTransacao,
        QParcelasTransacao,
        QDetalhesTransacao,
        QSegundaViaBoleto,
        QSegundaViaPix
    },

    mixins: [
        MixinBoleto,
        MixinPix,
        MixinMessages,
        MixinOrdemPagamento
    ],

    data() {
        return {
            ordemPagamento: {}
        }
    },

    computed: {
        parcelas() {
            return this.ordemPagamento.itens || []
        },

        transacao() {
            return Array.isArray(this.ordemPagamento.transacoes) ? this.ordemPagamento.transacoes ? this.ordemPagamento.transacoes[0] : {} : {}
        }
    },

    methods: {
        open(ordemPagamento) {
            this.showLoading({ message: 'Atualizando ordem de pagamento, Aguarde!' })
            this.$_buscaOrdemAtualizada(ordemPagamento.id)
        },

        $_buscaOrdemAtualizada(id){
            OrdensPagamentService.getOrdemPagamentoId(id)
                .then((valor) => {
                    this.hideLoading()
                    this.ordemPagamento = valor.data
                    this.$refs.modal.show()
                })
                .catch((erro) => {
                    this.hideLoading()
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao atualizar a ordem de pagamento',
                        error: erro
                    })
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>

export default {
    data() {
        return {
            ORDEM_NAO_PROCESSADA: 0,
            ORDEM_PROCESSADA: 1,
            ORDEM_CANCELADA: 2,
            ORDEM_DEVOLVIDA: 3,

            STATUS_PAGAMENTO: {
                0: 'AGUARDANDO PAGAMENTO / 2ª VIA',
                1: 'PAGAMENTO PROCESSADO',
                2: 'PAGAMENTO CANCELADO',
                3: 'PAGAMENTO DEVOLVIDO'
            },

            STATUS_TRANSACAO: {
                0: 'Pagamento não finalizado',
                1: 'Pagamento Autorizado',
                2: 'Pagamento Confirmado',
                3: 'Pagamento Negado',
                10: 'Pagamento Cancelado',
                11: 'Pagamento Devolvido',
                12: 'Pagamento Pendente',
                13: 'Pagamento Abortado'
            }
        }
    }
}

export default {
    data() {
        return {
            BOLETO_SITUACAO_SALVO: 0,
            BOLETO_SITUACAO_EMITIDO: 1,
            BOLETO_SITUACAO_REGISTRADO: 2,
            BOLETO_SITUACAO_LIQUIDADO: 3,
            BOLETO_SITUACAO_BAIXADO: 4,
            BOLETO_SITUACAO_FALHA: 5,
            BOLETO_SITUACAO_REJEITADO: 6,

            STATUS_BOLETO: {
                0: 'Salvo',
                1: 'Emitido',
                2: 'Registrado',
                3: 'Liquidado',
                4: 'Baixado',
                5: 'Falha',
                6: 'Rejeitado'
            }
        }
    }
}

import ServicesAuth from './servicesAuth'

class PrestacoesServices extends ServicesAuth {
    constructor() {
        super({
            url: '/parcelas/apagar',
            config: {
                // Configurações/Params genéricas para todos os métodos desta classe
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    Authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }

    getPrestacoesAPagar(idCliente, idDependente, todos = true) {
        let params = { carne__id_cliente: idCliente, carne__id_dependente__isnull: 'True' }
        let expand = 'carne,carne.loja'
        let fields = 'id,numero_parcela,data_vencimento,status_vencimento,dias_atraso,valor_parcela,valor_juro,valor_desconto,valor_total,quitado,anterior_quitado,carne.numero_carne,carne.loja.numero_loja,carne.qtd_parcelas'

        if (todos){
            params = { carne__id_cliente: idCliente }
        }
        if (idDependente){
            params = { carne__id_cliente: idCliente, carne__id_dependente: idDependente }
            expand += ',carne.dependente'
            fields += ',carne.dependente.id,carne.dependente.nome'
        }
        return this.get({
            ...params,
            // Configurações específicas deste GET das "Prestações A Pagar"
            expand: expand,
            fields: fields,
            sort: 'data_vencimento,carne__data_emissao,carne__numero_carne',
            per_page: 1000
        })
    }
}

export default new PrestacoesServices()

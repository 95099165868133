<template>
    <div>
        <div class="row justify-center">
            <div
                :class="`row bg-${$q.dark.isActive ? 'dark' : 'white'} justify-center`"
                style="width: 100%; max-width: 1200px;"
            >
                <div
                    class="col-12"
                    style="width: 98%; margin-top: 10px; margin-bottom: 10px;"
                >
                    <template v-if="isLoadingCliente">
                        <q-skeleton
                            width="100%"
                            height="50px"
                        />
                    </template>
                    <template v-else>
                        <q-select
                            filled
                            label="Cliente"
                            color="primary"
                            v-model="cliente"
                            :options="clienteOptions"
                        />
                    </template>
                </div>
            </div>
        </div>

        <div class="row justify-center q-mt-sm">
            <q-limite
                :limite="limite.limite"
                :utilizado="limite.utilizado"
                :disponivel="limite.disponivel"
            />
        </div>

        <div class="row justify-center">
            <div
                class="col"
                style="width: 100%; max-width: 1200px;"
            >
                <q-card v-if="mostrarMensagemCreditoAtrado" class="col"  style="margin-top: 10px; ">
                    <q-banner-mensagem
                        mensagem="Identificamos que você possuí prestações atrasadas mais de 15 dias.
                        Pague suas prestações para reestabelecer seu limite"
                    >
                    </q-banner-mensagem>
                </q-card>
            </div>
        </div>

        <div class="row justify-center">
            <div
            class="col q-mb-xl"
            style="width: 100%; max-width: 1200px;"
            >
            <q-card style="margin-top: 10px;">
                    <q-tabs
                        v-model="tab"
                        inline-label
                        :breakpoint="0"
                        align="justify"
                        indicator-color="primary"
                        class="shadow-2"
                    >
                        <q-tab
                            name="extratos"
                            label="Parcelas a pagar"
                            class="bg-primary text-white"
                        />
                        <q-tab
                            name="pagamentos"
                            label="Segunda Via"
                            class="bg-grey-4 text-black"
                        />
                    </q-tabs>

                    <q-separator />

                    <q-tab-panels
                        v-model="tab"
                        animated
                        keep-alive
                    >
                        <q-tab-panel name="extratos">
                            <q-extratos
                                ref="tabExtratos"
                                :nome="cliente.label"
                                :todos="cliente.tipo === 0"
                                :id-cliente="[0,2].indexOf(cliente.tipo) >= 0 ? cliente.titular : cliente.value"
                                :id-dependente="cliente.tipo === 2 ? cliente.value : null"
                                @possuiPrestacoes="validaPossuiPrestacoes"
                            />
                        </q-tab-panel>

                        <q-tab-panel name="pagamentos">
                            <q-pagamentos
                                ref="tabPagamentos"
                                :nome="cliente.label"
                                :todos="cliente.tipo === 0"
                                :id-cliente="[0,2].indexOf(cliente.tipo) >= 0 ? cliente.titular : cliente.value"
                                :id-dependente="cliente.tipo === 2 ? cliente.value : null"
                            />
                        </q-tab-panel>
                    </q-tab-panels>
                </q-card>
            </div>
        </div>
        <footer class="fixed-bottom row">
            <q-btn
                :color="possuiPrestacoes ? 'green' : 'grey-8'"
                label="Selecionar Parcelas"
                class="full-width"
                style="height: 50px; margin-top: 10px;"
                :disable="!possuiPrestacoes"
                @click="redirecionaSelecaoParcelas"
            />
        </footer>
    </div>
</template>

<script>
import DependenteService from '@/services/dependente'
import ClienteService from '@/services/cliente'
import QPagamentos from '@/views/Pagamentos'
import QExtratos from '@/views/Extrato'
import QLimite from '@/components/Home/limite'
import QBannerMensagem from '@/components/shared/QBannerMensagem'
import MixinMessages from '@/mixins/mixinMessages'
import login from '@/plugins/login'

export default {
    name: 'PgPagamentosParcelas',

    components: {
        QPagamentos,
        QExtratos,
        QLimite,
        QBannerMensagem
    },

    mixins: [
        MixinMessages
    ],

    data() {
        return {
            tab: 'extratos',
            clienteOptions: [],
            cliente: {},
            limite: {},
            clienteId: null,
            isLoadingCliente: true,
            possuiPrestacoes: true,
            statusAnalisecredito: null,
            analiseCreditoAtraso: [4],
            mensagemBanner: '',
        }
    },

    computed: {
        usuario() {
            return login.usuarioLogado()
        },
        mostrarMensagemCreditoAtrado() {
            return this.analiseCreditoAtraso.includes(this.statusAnalisecredito)
        },
    },

    watch: {
        cliente: function(value) {
            if (value) {
                this.carregaLimite()
            }
        }
    },

    mounted() {
        this.getDadosClienteDependente()
    },

    methods: {
        getDadosClienteDependente() {
            const cliente = this.usuario
            if (cliente.id_dependente) {
                this.showLoading({ message: 'Buscando dados do Dependente, Aguarde!' })
                DependenteService.getDependente(cliente.id_dependente)
                    .then(({ data }) => {
                        this.hideLoading()
                        this.clienteOptions.push(
                            {
                                label: data.nome,
                                value: data.id,
                                titular: cliente.id,
                                tipo: 2
                            }
                        )
                        this.isLoadingCliente = false
                        this.cliente = this.clienteOptions[0]
                        this.carregaLimite()
                        this.$refs.tabExtratos.refresh()
                    })
                    .catch(e => {
                        this.errorMessage({
                            title: 'Ops, algo de errado aconteceu!',
                            message: 'Ocorreu um erro ao buscar os dados do cliente',
                            error: e
                        })
                    })
                    .finally(() => {
                        this.hideLoading()
                    })
            } else {
                this.showLoading({ message: 'Buscando dados do Cliente, Aguarde!' })
                DependenteService.get({ id_cliente: cliente.id })
                    .then(({ data }) => {
                        this.clienteOptions = [
                            { label: 'Todos', value: null, titular: cliente.id, tipo: 0 },
                            { label: cliente.nome, value: cliente.id, tipo: 1 }
                        ]
                        if (data.length > 0) {
                            this.clienteOptions = [
                                ...this.clienteOptions,
                                ...data.map(el => ({
                                    label: el.nome,
                                    value: el.id,
                                    titular: cliente.id,
                                    tipo: 2
                                }))
                            ]
                        }
                        this.isLoadingCliente = false
                        this.cliente = this.clienteOptions[0]
                        this.carregaLimite()
                        this.$refs.tabExtratos.refresh()
                    })
                    .catch(e => {
                        this.errorMessage({
                            title: 'Ops, algo de errado aconteceu!',
                            message: 'Ocorreu um erro ao buscar os dados do cliente.',
                            error: e
                        })
                    })
                    .finally(() => {
                        this.hideLoading()
                    })
            }
        },

        carregaLimite() {
            if (this.cliente.tipo === 0) {
                this.getLimiteCliente(this.cliente.titular)
            } else if (this.cliente.tipo === 1) {
                this.getLimiteCliente(this.cliente.value)
            } else if (this.cliente.tipo === 2) {
                this.getLimiteDependente(this.cliente.value)
            }
        },

        getLimiteCliente(id) {
            this.showLoading({ message: 'Buscando dados do Limite de Crédito, Aguarde!' })
            ClienteService.getLimite(id)
                .then(({ data }) => {
                    this.limite = {}
                    this.limite = data
                    this.statusAnalisecredito = data.analise_manutencao ? data.analise_manutencao.status : null
                })
                .catch(e => this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao buscar o cartão do cliente.',
                        error: e
                    })
                )
                .finally(() => {
                    this.hideLoading()
                })
        },

        getLimiteDependente(id) {
            this.showLoading({ message: 'Buscando dados do Limite de Crédito, Aguarde!' })
            DependenteService.getLimite(id)
                .then(({ data }) => {
                    this.limite = {}
                    this.limite = data
                    this.statusAnalisecredito = null
                })
                .catch(e => this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao buscar o cartão do dependente.',
                        error: e
                    })
                )
                .finally(() => {
                    this.hideLoading()
                })
        },

        redirecionaSelecaoParcelas() {
            switch (this.cliente.tipo) {
            case 0:
                this.$router.push(`/selecao-parcelas?idCliente=${this.cliente.titular}&tipoCliente=${this.cliente.tipo}`)
                break
            case 1:
                this.$router.push(`/selecao-parcelas?idCliente=${this.cliente.value}&tipoCliente=${this.cliente.tipo}`)
                break
            case 2:
                this.$router.push(`/selecao-parcelas?idCliente=${this.cliente.titular}&idDependente=${this.cliente.value}&tipoCliente=${this.cliente.tipo}`)
                break
            }
        },

        validaPossuiPrestacoes(valor) {
            this.possuiPrestacoes = valor
        },
    }
}
</script>

<style>
.q-vertical-space {
    margin-top: 10px; margin-bottom: 10px;
}

.q-tabs__arrow {
    visibility: hidden;
}

.q-tab__label {
    font-size: 13pt !important;
}
</style>

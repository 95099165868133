<template>
    <q-panel titulo="SEGUNDA VIA DO PIX">
        <q-pix :pix="transacaoPix" />
    </q-panel>
</template>

<script>

import QPix from '@/components/shared/QPix'
import QPanel from '@/components/shared/QPanel'

export default {
    name: 'QSegundaViaPix',

    components: {
        QPix,
        QPanel
    },

    props: {
        transacaoPix: {
            type: Object,
            default: () => ({
                link_emv: '',
                qrcode: {
                    tipo_arquivo: '',
                    imagem_base64: ''
                }
            })
        }
    }
}
</script>

<style>

</style>

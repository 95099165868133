<template>
    <div>
        <div v-if="carregandoExtrato">
            <div class="col">
                <div class="row">
                    <q-skeleton
                        class="q-top-space"
                        width="25%"
                        height="20px"
                    />
                </div>
                <div class="col q-top-space">
                    <q-table-skeleton />
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <q-skeleton
                        class="q-top-space"
                        width="25%"
                        height="20px"
                    />
                </div>
                <div class="col q-top-space">
                    <q-table-skeleton />
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="quantParcelasVencer || quantParcelasVencidas">
                <div v-if="transacoesPendentes">
                    <q-banner-mensagem
                        mensagem="Há pagamentos aguardando serem concluidos."
                    />
                </div>
                <q-lins-table
                    v-if="quantParcelasVencidas"
                    titulo="VENCIDAS"
                    :dados="prestVencidas"
                    :colunas="colunas"
                    campo-chave="id"
                    :linhas-por-pagina="0"
                    :selecao="selecao"
                    :valida-item-selecionado="$_validaItemSelecionado"
                    @itensSelecionados="$_selecionaItensVencidos"
                    @linhaClick="$_abreDetalheParcela"
                    :exibe-rodape="!modoSelecao"
                    :marcar-linha-ao-clicar="marcarLinhaAoClicar"
                >
                    <template
                        v-slot:bottom
                        class="col"
                    >
                        <div class="col justify-between row text-subtitle2">
                            <span>
                                {{ quantParcelasVencidas }}
                                Parcela{{ quantParcelasVencidas > 1 ? 's' : '' }}
                            </span>
                            <div class="justify-end text-subtitle2">
                                <span>
                                    R$ {{ valTotalParcelasVencidas.toFixed(2).replace('.', ',') }}
                                </span>
                            </div>
                        </div>
                    </template>
                </q-lins-table>

                <q-lins-table
                    v-if="quantParcelasVencer"
                    class="q-top-space"
                    titulo="A VENCER"
                    :dados="prestVencer"
                    :colunas="colunas"
                    campo-chave="id"
                    :linhas-por-pagina="0"
                    :selecao="selecao"
                    :valida-item-selecionado="$_validaItemSelecionado"
                    @itensSelecionados="$_selecionaItensVencer"
                    @linhaClick="$_abreDetalheParcela"
                    :exibe-rodape="!modoSelecao"
                    :marcar-linha-ao-clicar="marcarLinhaAoClicar"
                >
                    <template
                        v-slot:bottom
                        class="col"
                    >
                        <div class="col justify-between row text-subtitle2">
                            <span>
                                {{ quantParcelasVencer }}
                                Parcela{{ quantParcelasVencer > 1 ? 's' : '' }}
                            </span>
                            <div class="justify-end text-subtitle2">
                                <span>
                                    R$ {{ valTotalParcelasVencer.toFixed(2).replace('.', ',') }}
                                </span>
                            </div>
                        </div>
                    </template>
                </q-lins-table>
                <q-detalhe-parcela
                    ref="modDetalheParcela"
                    :parcela="parcelaSelecionada"
                />
                <q-card
                    v-if="modoSelecao"
                    class="q-mb-md"
                    style="margin-top: 5px;"
                >
                    <q-card-section>
                        <div class="col justify-between row text-subtitle2">
                            <span>
                                {{ quantParcelasVencerSelecionadas + quantParcelasVencidasSelecionadas }}
                                Parcela{{ (quantParcelasVencerSelecionadas + quantParcelasVencidasSelecionadas) > 1 ? 's' : '' }}
                            </span>
                            <div class="justify-end text-subtitle2">
                                <span>
                                    R$ {{ (valTotalParcelasVencerSelecionadas + valTotalParcelasVencidasSelecionadas).toFixed(2).replace('.', ',') }}
                                </span>
                            </div>
                        </div>
                    </q-card-section>
                </q-card>
            </div>
            <div
                v-else
                class="col justify-center"
            >
                <div class="center">
                    <q-card>
                        <q-card-section>
                            <div class="text-h6 text-center">
                                Não há parcelas a serem pagas!
                            </div>
                            <div class="text-subtitle2 text-center">
                                Aproveite e comece a usar agora o seu cartão comprando no site ou app
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PrestacoesServices from '@/services/prestacoes'
import OrdensPagamentService from '@/services/ordens_pagamento'

import QLinsTable from '@/components/shared/QLinsTable'
import QTableSkeleton from '@/components/shared/QTableSkeleton'
import QBannerMensagem from '@/components/shared/QBannerMensagem'

import QDetalheParcela from '@/views/DetalhesParcela'

import MixinMessages from '@/mixins/mixinMessages'

import { date } from 'quasar'

export default {
    name: 'QExtrato',

    components: {
        QLinsTable,
        QTableSkeleton,
        QDetalheParcela,
        QBannerMensagem
    },

    mixins: [
        MixinMessages
    ],

    props: {
        nome: {
            type: String,
            default: null
        },

        idCliente: {
            type: String,
            default: null
        },

        idDependente: {
            type: String,
            default: null
        },

        todos: {
            type: Boolean,
            default: true
        },

        selecao: {
            type: String,
            default: 'none'
        },

        marcarLinhaAoClicar: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            carregandoExtrato: true,
            quant_parcelas: 0,
            valor_total: 0,
            itensSelecionadosVencer: [],
            itensSelecionadosVencidos: [],
            transacoesPendentes: false,
            parcelaSelecionada: {},
            prestacoes: [],
            colunas: [
                {
                    name: 'vencimento',
                    label: 'Venc.',
                    required: true,
                    align: 'left',
                    field: row => date.formatDate(row.data_vencimento + 'T00:00:00', 'DD/MM/YY'),
                    format: val => `${val}`,
                    sortable: true
                },
                {
                    name: 'carne',
                    label: 'Carnê',
                    align: 'center',
                    field: row => row.carne.numero_carne
                },
                {
                    name: 'parcela',
                    label: 'Parc.',
                    align: 'center',
                    field: row => `${row.numero_parcela}/${row.carne.qtd_parcelas}`
                },
                {
                    name: 'total',
                    label: 'Total (R$)',
                    field: row => row.valor_total.toFixed(2).replace('.', ',')
                }
            ]
        }
    },

    computed: {
        prestVencer() {
            return this.prestacoes.filter(el => el.status_vencimento)
        },

        quantParcelasVencer() {
            return this.prestVencer.length
        },

        valTotalParcelasVencer() {
            return this.prestVencer.reduce((acc, el) => acc + el.valor_total, 0)
        },

        prestVencidas() {
            return this.prestacoes.filter(el => !el.status_vencimento)
        },

        quantParcelasVencidas() {
            return this.prestVencidas.length
        },

        valTotalParcelasVencidas() {
            return this.prestVencidas.reduce((acc, el) => acc + el.valor_total, 0)
        },

        quantParcelasVencerSelecionadas() {
            return this.itensSelecionadosVencer.length
        },

        quantParcelasVencidasSelecionadas() {
            return this.itensSelecionadosVencidos.length
        },

        valTotalParcelasVencerSelecionadas() {
            return this.itensSelecionadosVencer.reduce((acc, el) => acc + el.valor_total, 0)
        },

        valTotalParcelasVencidasSelecionadas() {
            return this.itensSelecionadosVencidos.reduce((acc, el) => acc + el.valor_total, 0)
        },

        modoSelecao() {
            return this.selecao !== 'none'
        }
    },

    watch: {
        nome: function() {
            this.refresh()
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        $_validaItemSelecionado(row, added) {
            if (this.modoSelecao) {
                const itens = this.prestacoes.filter(el => el.carne.numero_carne === row.carne.numero_carne)

                const isPrimeiro = itens.filter(el => el.numero_parcela < row.numero_parcela).length === 0
                if (added && isPrimeiro && !row.anterior_quitado) {
                    return {
                        exibeSelecao: false,
                        mensagem: 'Existem parcelas deste carnê que estão com pagamento sendo processado!'
                    }
                }

                const selecionados = [...this.itensSelecionadosVencidos, ...this.itensSelecionadosVencer]
                for (const item of itens) {
                    const foiSelecionado = (selecionados.filter(el => el.id === item.id).length > 0)
                    if ((item.numero_parcela < row.numero_parcela && added && !foiSelecionado) ||
                        (item.numero_parcela > row.numero_parcela && !added && foiSelecionado)) {
                        return {
                            exibeSelecao: false,
                            mensagem: 'As parcelas mais antigas deste carnê devem ser pagas primeiro!'
                        }
                    }
                }
            }
            return { exibeSelecao: true, mensagem: '' }
        },

        $_buscaPrestacoes() {
            this.carregandoExtrato = true
            this.$_limpar_prestacoes()
            this.showLoading({ message: 'Buscando prestações, Aguarde!' })
            PrestacoesServices
                .getPrestacoesAPagar(this.idCliente, this.idDependente, this.todos)
                .then(({ data }) => {
                    this.hideLoading()
                    this.carregandoExtrato = false
                    this.$emit('possuiPrestacoes', Boolean(data.length))
                    this.prestacoes = data
                })
                .catch(e => {
                    this.hideLoading()
                    this.carregandoExtrato = false
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao buscar os extratos',
                        error: e
                    })
                })
        },

        $_buscaTransacoesPendentes(){
            this.showLoading({ message: 'Verificando se existem transações pendentes, Aguarde!' })
            OrdensPagamentService
                .getOrdensPagamentoProcessamento(this.idCliente, this.idDependente)
                .then(({ data }) => {
                    this.hideLoading()
                    this.transacoesPendentes = Boolean(data.length)
                })
                .catch(e => {
                    this.hideLoading()
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao buscar as transações pendentes',
                        error: e
                    })
                })
        },

        $_limpar_prestacoes() {
            this.prestacoes = []
            this.itensSelecionadosVencer = []
            this.itensSelecionadosVencidos = []
        },

        $_abreDetalheParcela(row){
            if (!this.modoSelecao){
                this.parcelaSelecionada = row
                this.$refs.modDetalheParcela.show()
            }
        },

        $_selecionaItensVencidos(itens){
            this.itensSelecionadosVencidos = itens
            this.$_emitirItens(this.itensSelecionadosVencidos, this.itensSelecionadosVencer)
        },

        $_selecionaItensVencer(itens){
            this.itensSelecionadosVencer = itens
            this.$_emitirItens(this.itensSelecionadosVencidos, this.itensSelecionadosVencer)
        },

        $_emitirItens(itensVencidos, itensVencer){
            this.$emit('itensSelecionados', [...itensVencidos, ...itensVencer])
        },

        refresh() {
            if (this.idCliente) {
                this.$_buscaTransacoesPendentes()
                this.$_buscaPrestacoes()
            }
        }
    }
}
</script>

<style>
.q-top-space {
    margin-top: 15px
}
.icone-h6{
    margin-top: -7px;
    padding-right: 10px;
}
.q-table__title{
    font-size: small;
}
</style>

<template>
    <div
        class="row"
        v-if="showProcessamento"
    >
        <div
            class="col"
            v-if="carregandoProcessamento"
        >
            <div class="row">
                <q-skeleton
                    width="35%"
                    height="20px"
                />
            </div>
            <div class="row">
                <q-skeleton
                    class="q-top-space"
                    width="25%"
                    height="20px"
                />
            </div>
            <div class="col q-top-space">
                <q-table-skeleton />
                <q-separator class="q-top-space" />
            </div>
        </div>
        <div
            class="col"
            v-else
        >
            <div class="row">
                <span class="text-subtitle2 text-weight-medium text-blue-grey-4">
                    EM PROCESSAMENTO
                </span>
            </div>
            <div class="col q-top-space">
                <q-lins-table
                    :dados="processamento"
                    :colunas="colunas"
                    campo-chave="id"
                    :linhas-por-pagina="0"
                    :exibe-rodape="false"
                    @linhaClick="$_onRowClick"
                />
                <q-separator class="q-top-space" />
            </div>
        </div>
    </div>
</template>

<script>

import { date } from 'quasar'

import OrdensPagamentService from '@/services/ordens_pagamento'

import QLinsTable from '@/components/shared/QLinsTable'
import QTableSkeleton from '@/components/shared/QTableSkeleton'

import MixinOrdemPagamento from '@/mixins/mixinOrdemPagamento'
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QProcessamento',

    components: {
        QLinsTable,
        QTableSkeleton
    },

    mixins: [
        MixinOrdemPagamento,
        MixinMessages
    ],

    props: {
        nome: {
            type: String,
            required: true
        },

        idCliente: {
            type: String,
            default: null
        },

        idDependente: {
            type: String,
            default: null
        },

        todos: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            processamento: [],

            carregandoProcessamento: true,

            showProcessamento: true,

            colunas: [
                {
                    name: 'dataHora',
                    label: 'Data /Hora',
                    align: 'center',
                    field: row => date.formatDate(row.data_emissao, 'DD/MM/YY HH:mm')
                },
                {
                    name: 'status',
                    label: 'Status',
                    align: 'center',
                    field: row => this.STATUS_PAGAMENTO[row.status_pagamento]
                },
                {
                    name: 'valor_total',
                    label: 'Total (R$)',
                    align: 'right',
                    field: row => row.valor_total.toFixed(2).replace('.', ',')
                }
            ]
        }
    },

    computed: {
        nomeCliente() {
            return this.nome.toUpperCase()
        }
    },

    watch: {
        nome: function() {
            this.$_getEmProcessamento()
        }
    },

    mounted() {
        if (this.idCliente) {
            this.$_getEmProcessamento()
        }
    },

    methods: {
        $_getEmProcessamento() {
            this.carregandoProcessamento = true
            OrdensPagamentService.getOrdensPagamentoProcessamento(this.idCliente, this.idDependente, this.todos)
                .then(({ data }) => {
                    this.processamento = []
                    this.processamento = data
                    this.showProcessamento = this.processamento.length > 0
                    this.carregandoProcessamento = false
                })
                .catch(e => {
                    this.carregandoProcessamento = false
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao buscar as ordens de pagamento',
                        error: e
                    })
                })
        },

        refresh() {
            if (this.idCliente) {
                this.$_getEmProcessamento()
            }
        },

        $_onRowClick(row) {
            this.$emit('linhaClick', row)
            this.refresh()
        }
    }

}
</script>

<style>
.q-top-space {
    margin-top: 15px
}
</style>

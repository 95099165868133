<template>
    <q-modal-view
        id="modDetalhesParcela"
        ref="modal"
        title="Detalhes da parcela"
    >
        <q-card
            class="q-ma-sm"
        >
            <q-card-section class="justify-center col">
                <div class="row justify-between">
                    <q-input
                        class="col-6"
                        v-model="numeroCarne"
                        label="Carnê"
                        borderless
                        readonly
                    />
                    <q-input
                        class="col-6"
                        v-model="numeroParcela"
                        label="Parcela"
                        borderless
                        readonly
                    />
                </div>
                <div class="row justify-between">
                    <q-input
                        class="col-6"
                        v-model="loja"
                        label="Loja"
                        borderless
                        readonly
                    />
                    <q-input
                        class="col-6"
                        v-model="valorParcela"
                        label="Valor da parcela"
                        borderless
                        readonly
                    />
                </div>
                <div class="row justify-between">
                    <q-input
                        class="col-6"
                        v-model="jurosDesconto"
                        label="Juros/Descontos"
                        borderless
                        readonly
                    />
                    <q-input
                        class="col-6"
                        v-model="valorTotal"
                        label="Total"
                        borderless
                        readonly
                    />
                </div>
                <div class="row justify-between">
                    <q-input
                        class="col-6"
                        v-model="dataVencimento"
                        label="Vencimento"
                        borderless
                        readonly
                    />
                    <q-input
                        class="col-6"
                        v-model="diasAtraso"
                        label="Dias de atraso"
                        borderless
                        readonly
                    />
                </div>
            </q-card-section>
        </q-card>
    </q-modal-view>
</template>

<script>

import QModalView from '@/components/shared/QModalView'
import { date } from 'quasar'

export default {
    name: 'DetalhesParcela',

    components: {
        QModalView
    },

    props: {
        parcela: {
            type: Object,
            required: true
        }
    },

    computed: {
        numeroCarne(){
            return (this.parcela.carne && this.parcela.carne.numero_carne) ? this.parcela.carne.numero_carne : '-'
        },
        numeroParcela(){
            if (this.parcela.carne && this.parcela.carne.qtd_parcelas && this.parcela.numero_parcela){
                return this.parcela.numero_parcela + '/' + this.parcela.carne.qtd_parcelas
            }
            return '-'
        },
        valorParcela(){
            return this.parcela.valor_parcela ? 'R$ ' + this.parcela.valor_parcela.toFixed(2).replace('.', ',') : '-'
        },
        loja(){
            return (this.parcela.carne && this.parcela.carne.loja && this.parcela.carne.loja.numero_loja) ? 'Loja - ' + this.parcela.carne.loja.numero_loja : '-'
        },
        jurosDesconto(){
            if (this.parcela.valor_juro) {
                return 'R$ ' + this.parcela.valor_juro.toFixed(2).replace('.', ',')
            } else if (this.parcela.valor_desconto) {
                return 'R$ ' + this.parcela.valor_desconto.toFixed(2).replace('.', ',')
            }
            return 'R$ 0,00'
        },
        dataVencimento(){
            return this.parcela.data_vencimento ? date.formatDate(this.parcela.data_vencimento + 'T00:00:00', 'DD/MM/YYYY') : '-'
        },
        valorTotal(){
            return this.parcela.valor_total ? 'R$ ' + this.parcela.valor_total.toFixed(2).replace('.', ',') : '-'
        },
        diasAtraso(){
            return this.parcela.dias_atraso ? this.parcela.dias_atraso : 0
        }
    },

    methods: {
        show(){
            this.$refs.modal.show()
        }
    }
}
</script>

<style>

</style>

<template>
    <q-panel titulo="PARCELAS">
        <q-lins-table
            :dados="parcelas"
            :colunas="columns"
            campo-chave="id"
            :exibe-rodape="false"
            :linhas-por-pagina="0"
            @linhaClick="$_abreDetalheParcela"
        />
        <q-detalhe-parcela
            ref="modDetalheParcela"
            :parcela="parcelaSelecionada"
        />
    </q-panel>
</template>

<script>

import QPanel from '@/components/shared/QPanel'
import QLinsTable from '@/components/shared/QLinsTable'
import QDetalheParcela from '@/views/DetalhesParcela'

import { date } from 'quasar'

export default {
    name: 'QParcelasTransacao',

    components: {
        QPanel,
        QDetalheParcela,
        QLinsTable
    },

    props: {
        parcelas: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            columns: [
                {
                    name: 'vencimento',
                    label: 'Venc.',
                    required: true,
                    align: 'left',
                    field: row => row.data_vencimento + 'T00:00:00',
                    format: val => date.formatDate(val, 'DD/MM/YY'),
                    sortable: true
                },
                {
                    name: 'carne',
                    label: 'Carnê',
                    align: 'center',
                    field: row => row.carne.numero_carne
                },
                {
                    name: 'parcela',
                    label: 'Parc.',
                    align: 'center',
                    field: row => `${row.numero_parcela}/${row.carne.qtd_parcelas}`
                },
                {
                    name: 'total',
                    label: 'Total (R$)',
                    field: row => `${row.valor_total.toFixed(2).replace('.', ',')}`
                }
            ],
            parcelaSelecionada: {
                numero_parcela: 0,
                valor_parcela: 0,
                valor_juro: 0,
                valor_desconto: 0,
                data_vencimento: null,
                atraso: 0,
                carne: {
                    numero_carne: 0,
                    loja: {
                        numero_loja: 0
                    }
                }
            }
        }
    },

    methods: {
        $_abreDetalheParcela(row){
            this.parcelaSelecionada = row
            this.parcelaSelecionada.valor_parcela = row.valor
            this.parcelaSelecionada.valor_juro = row.juro
            this.$refs.modDetalheParcela.show()
        }
    }
}
</script>

<style lang="scss" scoped>
    .tamanho {
        max-height: 500px;
    }
</style>

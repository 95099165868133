export default {
    data() {
        return {
            PIX_SALVO: 0,
            PIX_EMITIDO: 1,
            PIX_LIQUIDADO: 2,
            PIX_EXPIRADO: 3,
            PIX_CANCELADO: 4,
            PIX_CANCELADO_PELO_BANCO: 5,

            STATUS_PIX: {
                0: 'Salvo',
                1: 'Emitido',
                2: 'Liquidado',
                3: 'Expirado',
                4: 'Cancelado',
                5: 'Cancelado pelo Banco'
            }
        }
    }
}

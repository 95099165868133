<template>
    <div class="row">
        <div
            v-if="carregandoEfetuados"
            class="col"
        >
            <div class="row">
                <q-skeleton
                    width="35%"
                    height="20px"
                />
            </div>
            <div class="row">
                <q-skeleton
                    class="q-top-space"
                    width="25%"
                    height="20px"
                />
            </div>
            <div class="col q-top-space">
                <q-table-skeleton />
            </div>
        </div>
        <div
            v-else
            class="col"
        >
            <div class="row">
                <span class="text-subtitle2 text-weight-medium text-blue-grey-4">
                    PAGAMENTOS PROCESSADOS
                </span>
            </div>
            <div
                v-if="mostraMensagem"
                class="col q-top-space"
            >
                <div class="center">
                    <q-card>
                        <q-card-section>
                            <div class="text-h6 text-center">
                                Nenhum registro de pagamento encontrado!
                            </div>
                            <div class="text-subtitle2 text-center">
                                Aproveite e comece a usar agora o seu cartão comprando em nosso site ou app:
                                <a
                                    :href="urlEcommerce"
                                    target="_blank"
                                    style="text-decorations:none; color:inherit;"
                                >
                                    Clique aqui para acessar
                                </a>
                            </div>
                        </q-card-section>
                    </q-card>
                </div>
            </div>
            <div
                v-else
                class="col q-top-space"
            >
                <q-lins-table
                    :dados="efetuados"
                    :colunas="colunas"
                    campo-chave="id"
                    :linhas-por-pagina="0"
                    :exibe-rodape="false"
                    @linhaClick="$_onRowClick"
                />
            </div>
        </div>
    </div>
</template>

<script>

import { date } from 'quasar'

import OrdensPagamentService from '@/services/ordens_pagamento'

import QLinsTable from '@/components/shared/QLinsTable'
import QTableSkeleton from '@/components/shared/QTableSkeleton'

import MixinOrdemPagamento from '@/mixins/mixinOrdemPagamento'
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QEfetuados',

    components: {
        QLinsTable,
        QTableSkeleton
    },

    mixins: [
        MixinOrdemPagamento,
        MixinMessages
    ],

    props: {
        nome: {
            type: String,
            required: true
        },

        idCliente: {
            type: String,
            default: null
        },

        idDependente: {
            type: String,
            default: null
        },

        todos: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            efetuados: [],

            carregandoEfetuados: true,

            colunas: [
                {
                    name: 'dataHora',
                    label: 'Data / Hora',
                    align: 'center',
                    field: row => date.formatDate(row.data_emissao, 'DD/MM/YY HH:mm')
                },
                {
                    name: 'status',
                    label: 'Status',
                    align: 'center',
                    field: row => this.STATUS_PAGAMENTO[row.status_pagamento]
                },
                {
                    name: 'valor_total',
                    label: 'Total (R$)',
                    align: 'right',
                    field: row => row.valor_total.toFixed(2).replace('.', ',')
                }
            ]
        }
    },

    computed: {
        nomeCliente() {
            return this.nome.toUpperCase()
        },

        mostraMensagem() {
            return this.efetuados.length === 0
        },

        urlEcommerce() {
            return process.env.VUE_APP_URL_ECOMMERCE
        }
    },

    watch: {
        nome: function() {
            this.$_getEfetuados()
        }
    },

    mounted() {
        if (this.idCliente) {
            this.$_getEfetuados()
        }
    },

    methods: {
        $_getEfetuados() {
            this.carregandoEfetuados = true
            this.efetuados = []
            OrdensPagamentService.getOrdensPagamentoEfetuados(this.idCliente, this.idDependente, this.todos)
                .then(({ data }) => {
                    this.efetuados = data
                    this.carregandoEfetuados = false
                })
                .catch(e => {
                    this.carregandoEfetuados = false
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Ocorreu um erro ao buscar as ordens de pagamento',
                        error: e
                    })
                })
        },

        refresh() {
            if (this.idCliente) {
                this.$_getEfetuados()
            }
        },

        $_onRowClick(row) {
            this.$emit('linhaClick', row)
        }
    }

}
</script>

<style>

</style>

<template>
    <div>
        <div class="row q-mt-sm">
            <div class="col">
                <span class="text-caption text-weight-medium text-blue-grey-4"> Número do cartão </span> <br>
                <span class="text-subtitle2">{{ debito.numero_cartao || '-' }}</span>
            </div>
            <div class="col">
                <span class="text-caption text-weight-medium text-blue-grey-4"> Bandeira </span> <br>
                <span class="text-subtitle2">{{ debito.bandeira || '-' }}</span>
            </div>
        </div>
        <div class="row q-mt-sm">
            <div class="col">
                <span class="text-caption text-weight-medium text-blue-grey-4"> Autorização </span> <br>
                <span class="text-subtitle2">{{ debito.autorizacao || '-' }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QDetalhesDebito',

    props: {
        debito: {
            type: Object,
            required: true
        }
    },

    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>

</style>

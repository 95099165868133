<template>
    <q-panel titulo="TRANSAÇÕES">
        <div
            v-if="transacao"
            class="col"
        >
            <div class="row q-mt-sm">
                <div class="col">
                    <span class="text-caption text-weight-medium text-blue-grey-4">Data/Hora</span>
                    <br>
                    <span class="text-subtitle2">{{ dataHora }}</span>
                </div>
                <div class="col">
                    <span class="text-caption text-weight-medium text-blue-grey-4">Total</span>
                    <br>
                    <span class="text-subtitle2">R$ {{ valorTotal }}</span>
                </div>
            </div>
            <div
                class="row q-mt-sm"
                v-if="mostrarStatus"
            >
                <div class="col">
                    <span class="text-caption text-weight-medium text-blue-grey-4">Status</span>
                    <br>
                    <span class="text-subtitle2">
                        {{ status }}
                    </span>
                </div>
                <div class="col">
                    <span class="text-caption text-weight-medium text-blue-grey-4">Tipo</span>
                    <br>
                    <span class="text-subtitle2">{{ transacao.tipo || '-' }}</span>
                </div>
            </div>
            <div
                class="row q-mt-sm"
                v-if="transacao.pix"
            >
                <div
                    class="col"
                    v-if="transacao.pix.situacao === PIX_EMITIDO"
                >
                    <span class="text-caption text-weight-medium text-blue-grey-4">Vencimento</span>
                    <br>
                    <span class="text-subtitle2">{{ vencimento }}</span>
                </div>
                <div class="col">
                    <span class="text-caption text-weight-medium text-blue-grey-4">Recebimento</span>
                    <br>
                    <span class="text-subtitle2">{{ recebimento }}</span>
                </div>
            </div>
            <div v-if="transacao.debito">
                <q-detalhes-debito :debito="transacao.debito" />
            </div>
            <div v-else-if="transacao.boleto && statusPagamento !== ORDEM_CANCELADA">
                <q-detalhes-boleto :boleto="transacao.boleto" />
            </div>
        </div>
    </q-panel>
</template>

<script>
import { date } from 'quasar'

import QPanel from '@/components/shared/QPanel'
import QDetalhesDebito from './detalhesDebito'
import QDetalhesBoleto from './detalhesBoleto'

import MixinPix from '@/mixins/mixinPix'
import MixinBoleto from '@/mixins/mixinBoleto'
import MixinOrdemPagamento from '@/mixins/mixinOrdemPagamento'

export default {
    name: 'QDetalhesTransacao',

    components: {
        QPanel,
        QDetalhesDebito,
        QDetalhesBoleto
    },

    mixins: [
        MixinOrdemPagamento,
        MixinBoleto,
        MixinPix
    ],

    props: {
        transacao: {
            type: Object,
            required: true
        },

        statusPagamento: {
            type: Number,
            required: true
        }
    },

    computed: {
        dataHora() {
            return date.formatDate(this.transacao.data_emissao, 'DD/MM/YYYY HH:mm') || '-'
        },

        status() {
            if (this.transacao.debito) {
                return this.STATUS_TRANSACAO[this.transacao.debito.codigo_retorno]
            } else if (this.transacao.boleto) {
                return this.STATUS_BOLETO[this.transacao.boleto.situacao]
            } else if (this.transacao.pix) {
                return this.STATUS_PIX[this.transacao.pix.situacao]
            }
            return '-'
        },

        valorTotal() {
            return String(Number(this.transacao.valor).toFixed(2)).replace('.', ',') || '-'
        },

        recebimento() {
            return date.formatDate(this.transacao.data_recebimento, 'DD/MM/YYYY HH:mm') || '-'
        },

        vencimento() {
            return date.formatDate(date.addToDate(this.transacao.data_emissao, { days: 1 }), 'DD/MM/YYYY HH:mm')
        },

        mostrarStatus() {
            return this.transacao.boleto || this.transacao.debito || this.transacao.pix
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
